import Link from "next/link"

import Gradient from "interfaces/Gradient"
import Like from "components/Like"
import Copy from "components/Copy"
import useUser from "user/useUser"
import Creation from "interfaces/Creation"
import Profile from "interfaces/Profile"

type Props = {
	liked: string[]
	gradient: Gradient
}

const Thumbnail: React.FC<Props> = ({ liked, gradient }) => {
	const { data: user } = useUser()
	const likeStatus = gradient
		? liked.includes(gradient.id)
			? "liked"
			: "notLiked"
		: "unknown"

	const creation = (gradient.creations as Creation[]).at(0)
	const creator = creation?.profile as Profile

	return (
		<section className="flex w-full flex-col">
			<span className="cursor-pointer group sm:motion-safe:hover:-translate-y-2 md:motion-safe:hover:scale-105 transition">
				<Link href={`/gradients/${gradient.id}`}>
					<div className="grid grid-cols-1 grid-rows-1">
						<div
							className="aspect-square w-full rounded col-start-1 row-start-1 col-span-1 row-span-1"
							style={{ background: gradient.style }}
						/>
						<div
							className="aspect-square w-full rounded -z-10 col-start-1 row-start-1 col-span-1 row-span-1 group-hover:blur transition-all motion-reduce:duration-75"
							style={{ background: gradient.style }}
						/>
					</div>
				</Link>
			</span>

			<div className="flex flex-row justify-between items-center">
				<div className="flex flex-col">
					<span className="font-medium -mb-0.5">
						<Link href={`/gradients/${gradient.id}`}>
							<span>{gradient.name}</span>
						</Link>
					</span>
					<span className="text-xs">
						by{" "}
						<Link href={`/profiles/${creator.id}`}>
							<span>{creator.name}</span>
						</Link>
					</span>
				</div>

				<div className="flex flex-row items-center justify-end gap-0.5">
					{user && <Like status={likeStatus} gradient={gradient} />}
					<Copy
						text={gradient.style || ""}
						message={`Copied ${
							gradient.name || "gradient"
						} to clipboard`}
					/>
				</div>
			</div>
		</section>
	)
}

export default Thumbnail
