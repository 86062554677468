import useUser from "user/useUser"
import Gradient from "interfaces/Gradient"
import Profile from "interfaces/Profile"
import Thumbnail from "components/Thumbnail"

const loadingPulsations = Array.from({length: 16}, (_, i) => i)

type Props = {
	status: "error" | "loading" | "success"
	gradients?: Gradient[]
}

const liked = (user?: Profile): string[] => {
	if (!user || !user.likes) return []
	return user.likes.map((like) => (like.gradient as any) || "")
}

const Gallery: React.FC<Props> = ({ status, gradients }) => {
	const { data: user } = useUser()

	if (status === "error")
		return (
			<section className="w-full flex items-center justify-center h-96 font-medium">
				<p>Could not load gradients due to an error on the server.</p>
			</section>
		)

	if (status === "success" && gradients)
		return (
			<section className="grid w-full grid-cols-1 gap-3 sm:grid-cols-2 sm:gap-2 md:grid-cols-4 md:gap-3">
				{gradients.map((gradient) => (
					<Thumbnail
						key={gradient.id}
						gradient={gradient}
						liked={liked(user)}
					/>
				))}
			</section>
		)


	return <section className="grid w-full grid-cols-1 gap-3 sm:grid-cols-2 sm:gap-2 md:grid-cols-4 md:gap-3">
		{loadingPulsations.map((_, index) => (
			<section className="flex w-full flex-col"
				key={index}
			>
				<span className="aspect-square w-full rounded bg-slate-200 mb-8 motion-safe:animate-pulse"></span>
			</section>
		))}
	</section>
}

export default Gallery
