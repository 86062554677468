import type { NextPage } from "next"
import Link from "next/link"
import { useQuery } from "@tanstack/react-query"
import { useState } from "react"
import useUser from "user/useUser"

import Header from "sections/Header"
import Gallery from "sections/Gallery"
import Gradient from "interfaces/Gradient"
import Acrylic from "components/Acrylic"
import Sort from "types/sort"
import useScrollPosition from "useScrollPosition"

const Filters: React.FC<{ sort: Sort; setSort: (sort: Sort) => void }> = ({
  sort,
  setSort,
}) => {
  return (
    <Acrylic className="rounded-full flex flex-row text-xs">
      <button
        onClick={() => setSort("most-popular")}
        className={[
          "rounded-l-full pl-3 pr-2 py-1 transition-all",
          sort === "most-popular"
            ? "bg-slate-800 text-white"
            : "text-slate-900 hover:bg-slate-900 hover:text-white",
        ].join(" ")}
      >
        Most Popular
      </button>
      <button
        onClick={() => setSort("newest")}
        className={[
          "rounded-r-full pl-2 pr-3 py-1 transition-all",
          sort === "newest"
            ? "bg-slate-800 text-white"
            : "text-slate-900 hover:bg-slate-900 hover:text-white",
        ].join(" ")}
      >
        Newest
      </button>
    </Acrylic>
  )
}

const Home: NextPage = () => {
  const { data: user } = useUser()
  const [sort, setSort] = useState<Sort>("most-popular")
  const scrollPosition = useScrollPosition()

  const { status, data: gradients } = useQuery<Gradient[], Error>(
    [`gradients-${sort}`],
    async () => {
      const response = await fetch(`/api/gradients?sort=${sort}`)

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      const data = await response.json()
      return data
    }
  )

  return (
    <div className="w-full max-w-6xl mx-auto py-2 px-1">
      <Header />
      <section
        className={[
          "z-40 sticky top-8 py-2 flex flex-row justify-between text-sm font-medium rounded-full transition-all",
          scrollPosition > 10 ? "px-1" : "px-0",
        ].join(" ")}
      >
        <Filters sort={sort} setSort={setSort} />
        {user && (
          <Acrylic className="rounded-full px-2 py-1 transition text-slate-900 hover:bg-slate-900 hover:text-white">
            <Link href="/create">Create</Link>
          </Acrylic>
        )}
      </section>
      <Gallery status={status} gradients={gradients} />
    </div>
  )
}

export default Home
